import 'chart.js/auto';
import 'dayjs/locale/ru';
import React from 'react';
import dayjs from 'dayjs';
import { QueryClient } from 'react-query';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './modules/router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10 * 60 * 1000,
    },
  },
});

const App = () => {
  dayjs.locale('ru');
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Router />
          <ReactQueryDevtools initialIsOpen={false} />
        </BrowserRouter>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};

export default App;
